/* eslint-disable no-restricted-globals */
import React, { ReactNode, HTMLInputTypeAttribute } from 'react'
import styled from 'styled-components'

import Modal from '../Modal'
import mediaqueries from '../../styles/media'

export const ModalButton: React.FC<{
  [prop: string]: any
  content: ReactNode | HTMLInputTypeAttribute
  link: string
}> = ({ link, children }) => {
  // @ts-ignore
  const [modalVisibility, setmodalVisibility] = React.useState(false)
  const [contentLink, setContentLink] = React.useState()
  const [show, setShow] = React.useState()

  const setModalVisibilityL = (show) => {
    setmodalVisibility(!!show)
  }

  return (
    <ActionBar>
      <ActionButton onClick={() => setModalVisibilityL(true)}>{children}</ActionButton>
      <Modal
        show={modalVisibility}
        link={link}
        setShow={(show) => setmodalVisibility(show)}
        className={'video-modal-style'}
      ></Modal>
    </ActionBar>
  )
}

export default ModalButton

var ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const ActionButton = styled.button`
  /* It's a CSS comment. */
  font-family: ${(p) => p.theme.fonts.menu};
  font-weight: ${(p) => p.theme.fonts.weights.heavy};
  font-size: ${(p) => p.theme.fontSizes[5]}px;
  width: 100%;
  color: ${(p) => p.theme.colors.grey};
  -webkit-appearance: button;
  display: flex;
  flex-flow: column;
  align-items: center;
  box-sizing: border-box;
  margin: 0;
  overflow: visible;
  text-align: left;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  line-height: 2.2;
  width: 100%;
  /* margin-top: 1.5rem !important;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  border-radius: 2px;
  outline: none;
  transition: 0.15s linear;
  cursor: pointer;
  padding: 0; */

  a {
    display: flex;
    flex-flow: row wrap;

    * {
      align-self: center;
    }
  }
  > div {
    width: 100%;
    text-align: center;
    img {
      width: 100%;
    }
  }

  svg {
    font-size: ${(p) => p.theme.fontSizes[1]}px;
  }
  /* 
  img {
    width: 100%;
    height: auto;
    max-height: 200px;
    max-width: 150px;
  } */
`